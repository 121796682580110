<template>
  <div style="position: sticky;top: 0;z-index: 1000">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand href="#" @click="goBranch('/welcome')" class="m-3 my-2">
        <b-icon icon="people-fill"></b-icon>
        亚信后台管理系统
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-container fluid>
        <b-collapse id="nav-collapse" is-nav style="justify-content: right">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="mr-3">
            <b-nav-item-dropdown style="float: right">
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <b-avatar src="https://placekitten.com/300/300"></b-avatar>
                <em class="ml-1">{{ staff.name }}</em>
              </template>
              <b-dropdown-item href="#" @click="goBranch('/resetName')">修改名字</b-dropdown-item>
              <b-dropdown-item href="#" @click="goBranch('/resetPassword')">修改密码</b-dropdown-item>
              <b-dropdown-item href="#" @click="logOut()">登出</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data() {
    return {
      staff: {}
    }
  },
  mounted() {
    this.staff = JSON.parse(localStorage.getItem('staffInfo'))
  },
  methods: {
    goBranch(url) {
      this.$router.push({path: url})
    },
    logOut() {
      localStorage.removeItem('token')
      localStorage.removeItem('staffInfo')
      this.$router.push({path: '/'})
    }
  }
}
</script>

<style scoped>

</style>
