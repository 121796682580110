<template>
  <div class="bg-light" style="min-height: 100vh">
    <Header></Header>
    <div id="sideBar" style="background-color: #545c64">
      <el-menu
          unique-opened
          default-active="1"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item index="1" @click="goBranch('/welcome')">
          <i class="el-icon-s-home"></i>
          <span slot="title">主页</span>
        </el-menu-item>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>组织管理</span>
          </template>
          <el-menu-item v-if="usageOrganization" index="2-1" @click="goBranch('/organization')">
            <span slot="title">组织管理</span>
          </el-menu-item>
          <el-menu-item v-if="usageArea" index="2-2" @click="goBranch('/searchArea')">查找地区</el-menu-item>
          <el-menu-item v-if="usageOrganization" index="2-3" @click="goBranch('/searchOrganization')">查找组织</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>工号管理</span>
          </template>
          <el-menu-item index="3-1" @click="goBranch('/searchStaff')">查找职员</el-menu-item>
          <el-menu-item index="3-2" @click="goBranch('/createStaff')">
            <span slot="title">添加员工</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-if="usageAuthority" index="4" @click="goBranch('/authority')">
          <i class="el-icon-s-opportunity"></i>
          <span slot="title">权限管理</span>
        </el-menu-item>
        <el-menu-item v-if="usageRole" index="5" @click="goBranch('/role')">
          <i class="el-icon-s-check"></i>
          <span slot="title">角色管理</span>
        </el-menu-item>
        <el-menu-item v-if="usageOperationLog" index="6" @click="goBranch('/journal')">
          <i class="el-icon-document"></i>
          <span slot="title">日志管理</span>
        </el-menu-item>
        <el-submenu index="7">
          <template slot="title">
            <i class="el-icon-share"></i>
            <span>信息管理</span>
          </template>
          <el-menu-item v-if="usageMessage" index="7-1" @click="goBranch('/message')">
            <i class="el-icon-folder-opened"></i>
            <span slot="title">消息收发</span>
          </el-menu-item>
          <el-menu-item v-if="usageNotice" index="7-2" @click="goBranch('/notice')">
            <i class="el-icon-message-solid"></i>
            <span slot="title">公告便签</span>
          </el-menu-item>
          <el-menu-item v-if="usageCommunication" index="7-3" @click="goBranch('/communication')">
            <i class="el-icon-chat-round"></i>
            <span slot="title">信息交流</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div style="margin-left: 210px">
      <vue-page-transition name="fade-in-down">
        <router-view/>
      </vue-page-transition>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Common/Header'
import {showMyAuthorities} from "@/api";

export default {
  name: 'index',
  components: {
    Header
  },
  data() {
    return {
      myAuthorities: [],
      usageOrganization: false,
      usageArea: false,
      usageAuthority: false,
      usageRole: false,
      usageOperationLog: false,
      usageMessage: false,
      usageCommunication: false,
      usageNotice: false
    }
  },
  mounted() {
    this.showMyAuthorities()
  },
  methods: {
    goBranch(url) {
      this.$router.push({path: url})
    },
    showMyAuthorities() {
      showMyAuthorities().then(res => {
        if (res.data.status) {
          this.myAuthorities = res.data.data
          this.myAuthorities.forEach(auth => {
            switch (auth.id) {
              case 1:
                this.usageOrganization = true
                break
              case 2:
                this.usageArea = true
                break
              case 3:
                this.usageAuthority = true
                break
              case 4:
                this.usageRole = true
                break
              case 5:
                this.usageOperationLog = true
                break
              case 6:
                this.usageMessage = true
                break
              case 7:
                this.usageCommunication = true
                break
              case 8:
                this.usageNotice = true
                break
              default:
                break
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
#sideBar {
  position: fixed;
  height: 100vh;
  color: #fff;
  width: 200px;
}
</style>
